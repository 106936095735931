//add Class to visible slides
const checkActiveSlides = ( e, slick, currentSlide, nextSlide ) => {
  const slidesToShowItems = slick.options.slidesToShow,
    slidesCount = slick.$slides.length,
    slidesInvisible = slidesCount - nextSlide <= slidesToShowItems; // check all slides are in from right side
  $( e.target ).find( '.slick-slide' ).removeClass( 'bs-slick-inview' );
  if ( slidesCount <= slidesToShowItems ) {
    $( e.target ).find( '.slick-slide' ).addClass( 'bs-slick-inview' );
  } else {
    // eslint-disable-next-line no-lonely-if
    if ( !slidesInvisible ) {
      $( e.target )
        .find( '.slick-slide' )
        .slice( nextSlide, slidesToShowItems + nextSlide )
        .addClass( 'bs-slick-inview' );
    } else {
      $( e.target )
        .find( '.slick-slide' )
        .slice( slidesCount - slidesToShowItems )
        .addClass( 'bs-slick-inview' );
    }
  }
};
// video init function
const videoInitFunc = ( slickSlider ) => {
  const videos = slickSlider.find( '.slick-slide video' );
  videos.each( ( index, element ) => {
    $( element ).get( 0 ).pause();
    $( element ).get( 0 ).currentTime = 0;
  } );
};
// video after change
const videoAfterChangeFunc = ( slickSlider, slick ) => {
  slickSlider.find( '.slick-slide video' ).each( ( index, element ) => {
    $( element ).get( 0 ).pause();
  } );
  const currSlide = slickSlider.find( '.slick-slide' ).not( '.slick-cloned' ).eq( slick.currentSlide );
  if ( currSlide.find( 'video[autoplay]' ).length > 0 ) {
    setTimeout( () => {
      currSlide.find( 'video[autoplay]' ).get( 0 ).play();
      currSlide.find( 'video[autoplay]' ).not( 'video[muted]' ).eq( 0 ).prop( 'muted', false );
    }, slick.options.speed );
  }
};
// slick observe
const slickObserver = ( slickSlider, slick ) => {
  const slickObserve = new IntersectionObserver(
    entries => {
      const entry = entries[0];
      const currentSlide = slickSlider.find( '.slick-slide' ).not( '.slick-cloned' ).eq( slick.currentSlide );
      if ( entry.isIntersecting ) {
        if ( currentSlide.find( 'video[autoplay]' ).length > 0 ) {
          currentSlide.find( 'video' ).get( 0 ).play();
        }
      } else {
        if ( currentSlide.find( 'video[autoplay]' ).length > 0 ) {
          currentSlide.find( 'video' ).get( 0 ).pause();
        }
      }
    },
    { root: null, threshold: 0.5 } );
  slickObserve.observe( slickSlider[0] );
};
// check slide direction
const slideDirection = ( e, slick, currentSlide, nextSlide ) => {
  let dir;
  if ( ( currentSlide < nextSlide && currentSlide === nextSlide - 1 ) ||
    ( currentSlide === slick.slideCount - 1 && nextSlide === 0 ) ) {
    dir = 'right';
  }
  else if ( nextSlide < currentSlide || ( nextSlide === slick.slideCount - 1 && currentSlide === 0 ) ) {
    dir = 'left';
  }
  $( e.target ).addClass( `bs-slide-move-${dir}` );
};
// when click on popup active the slide
const changeActiveSlide = ( e ) => {
  $( e.target ).on( 'click', '.slick-slide:not(.slick-current) .media-elements [data-fancybox]', ( eve ) => {
    const clickedId = $( eve.currentTarget )
      .parents( '.slick-slide' )
      .attr( 'data-slick-index' );
    $( e.target ).slick( 'slickGoTo', clickedId );
  } );
};
// autoplay reverse fix
const autoplayReverseFix = ( e, slick, currentSlide ) => {
  const isLastSlideActive = currentSlide === slick.$slides.length - 1,
    isAutoplay = slick.options.autoplay,
    isInfinite = slick.options.infinite;
  if ( isAutoplay && !isInfinite ) {
    if ( isLastSlideActive ) {
      const currSlider = $( e.target ),
        progressBar = currSlider.find( '.slick-slide' ).eq( currentSlide ).find( '.slick-slide-animator' );
      slick.direction = 1;
      if ( progressBar.length > 0 ) {
        progressBar.on( 'animationend', () => {
          setTimeout( () => { currSlider.slick( 'slickGoTo', 0 ); }, slick.options.speed );
        } );
      }
    }
  }
};
// clone tab title to content slide in tabsliders
const cloneTabTitle = ( tabSlider ) => {
  const tabs = $( tabSlider ).find( '.bs-slider-tabs .slick-slide-wrapper' ),
    slides = $( tabSlider ).find( '.bs-slider-content .slick-slide-wrapper' );
  let maxH = 0;
  tabs.each( ( i, tab ) => {
    const title = $( tab ).html(),
      titleWrapper = `<div class="slide-title-wrapper">${title}</div>`;
    if ( slides.eq( i ).find( '.slide-title-wrapper' ).length === 0 ) {
      slides.eq( i ).prepend( titleWrapper );
    }
    const titleH = slides.eq( i ).find( '.slide-title-wrapper' ).height();
    maxH = maxH < titleH ? titleH : maxH;
  } );
  slides.find( '.slide-title-wrapper' ).height( maxH );
};
// equal height to content
const equalContent = ( tabSlider ) => {
  const tabs = $( tabSlider ).find( '.bs-slider-tabs .slick-slide-wrapper' ),
    slides = $( tabSlider ).find( '.bs-slider-content .slick-slide-wrapper' );
  let counterMaxH = 0;
  let testimonialMaxH = 0;
  tabs.each( ( i ) => {
    const counterH = slides.eq( i ).find( '.bs-div--home-counter > .bs-div__inner' ).height();
    const testimonialH = slides.eq( i ).find( '.bs-div--content-testimonial > .bs-div__inner' ).height();

    counterMaxH = counterMaxH < counterH ? counterH : counterMaxH;
    testimonialMaxH = testimonialMaxH < testimonialH ? testimonialH : testimonialMaxH;
  } );
  slides.find( '.bs-div--home-counter' ).height( counterMaxH );
  slides.find( '.bs-div--content-testimonial' ).height( testimonialMaxH );
};

// clone tab title to content slide in tabsliders
const cloneMapButton = ( tabSlider ) => {
  const slides = $( tabSlider ).find( '.bs-slider-content .slick-slide-wrapper' );
  slides.each( ( i, slide ) => {
    const button = $( slide ).find( '.bs-div--home-tab-slider-image .bs-pro-button' );
    if ( !$( slide ).hasClass( 'map-button-cloned' ) && button.length > 0 ) {
      $( slide ).find( '.bs-div--home-tab-slider-content > div' )
        .append( button.clone().addClass( 'bs-pro-button--view_map' ) );
      $( slide ).addClass( 'map-button-cloned' );
    }
  } );
};
// add custom event for slick buttons
const addArrowClick = ( slickSlider, slick ) => {
  const navArrows = slickSlider.find( '.slick-arrow' ),
    isAutoplay = slick.options.autoplay,
    isInfinite = slick.options.infinite;
  if ( navArrows.length > 0 && isAutoplay && !isInfinite ) {
    navArrows.on( 'click', ( e ) => {
      if ( $( e.target ).hasClass( 'slick-disabled' ) ) {
        if ( $( e.target ).hasClass( 'slick-prev' ) ) {
          slickSlider.slick( 'slickGoTo', slick.$slides.length - 1 );
        } else {
          slickSlider.slick( 'slickGoTo', 0 );
        }
      }
    } );
  }
};
// convert to Vertical Slider
const verticalSlider = ( slider ) => {
  const currSlider = $( slider ),
    currSlickData = currSlider.data( 'slick' );
  // eslint-disable-next-line array-callback-return
  Object.values( currSlickData.responsive ).forEach( ( point ) => {
    if ( point.settings !== 'unslick' && point.breakpoint > 993 ) {
      point.settings.vertical = true;
      point.settings.verticalSwapping = true;
    }
  } );
  currSlider.data( 'slick', currSlickData );
};
// slick autoplay start
const startAutoplay = ( entry, sliders ) => {
  $.each( sliders, ( index, element ) => {
    const slickSlider = $( element );
    const slickObject = slickSlider.slick( 'getSlick' );
    const initialSlide = slickObject.options.initialSlide;
    if ( slickObject.options.autoplay ) {
      // start autoplay when slider is in viewport
      slickSlider.slick( 'slickPlay' );
      slickSlider.find( `.slick-slide[data-slick-index="${initialSlide}"]` ).addClass( 'progress-start' );
      $( entry.target ).addClass( 'observed-once' );
    }
  } );
};
// slick autoplay pause
const pauseAutoplay = ( sliders ) => {
  $.each( sliders, ( index, element ) => {
    const slickSlider = $( element );
    const slickObject = slickSlider.slick( 'getSlick' );
    if ( slickObject.options.autoplay ) {
      slickSlider.eq( 0 ).slick( 'slickPause' );
    }
  } );
};
const pauseOnClick = ( sliders ) => {
  sliders.addClass( 'bs-slick-paused' );
  pauseAutoplay( sliders );
};
// remove asNav for sticky Slider 
const removeAsNav = ( slider, breakpoint ) => {
  const currSlider = $( slider ),
    currSlickData = currSlider.data( 'slick' );
  // eslint-disable-next-line array-callback-return
  Object.values( currSlickData.responsive ).forEach( ( point ) => {
    if ( point.settings !== 'unslick' && point.breakpoint > breakpoint ) {
      delete point.settings.asNavFor;
    }
  } );
  currSlider.data( 'slick', currSlickData );
};
// Remove PauseonFocus Slider
const removePauseOption = ( slider ) => {
  const currSlider = $( slider ),
    currSlickData = currSlider.data( 'slick' );
  // eslint-disable-next-line array-callback-return
  Object.values( currSlickData.responsive ).forEach( ( point ) => {
    if ( point.settings !== 'unslick' ) {
      point.settings.pauseOnHover = false;
      point.settings.pauseOnFocus = false;
    }
  } );
  currSlider.data( 'slick', currSlickData );
};
const setActiveEventsTab = () => {
  const postPagination = $( '.bs-posts__pagination' ),
    paginationItems = postPagination.find( '.page-item' );
  paginationItems.each( ( i ) => {
    paginationItems[i].addEventListener( 'click', () => {
      localStorage.setItem( 'pastEventTabActive', true );
    } );
  } );
};
const changeActiveEventsTab = ( sliders ) => {
  const pastEventTab = localStorage.getItem( 'pastEventTabActive' );
  if ( pastEventTab ) {
    setTimeout( () => {
      sliders.each( ( i, slider ) => {
        const currSlider = $( slider );
        currSlider.slick( 'slickGoTo', 1 );
        localStorage.removeItem( 'pastEventTabActive' );
      } );
    }, 150 );
  }
};
const slideCounters = ( e, slick, slideId, type ) => {
  const currSlider = $( e.target ),
    slideEl = currSlider.find( '.slick-slide' ).not( '.slick-cloned' ).eq( slideId ),
    isCountedOnce = slideEl.hasClass( 'counted-once' ),
    scrollDuration = slick.options.speed,
    nextCounters = slideEl.find( '[data-counterup]' );
  if ( nextCounters.length > 0 && !isCountedOnce ) {
    nextCounters.each( ( id, nextCounter ) => {
      console.log( nextCounter );
      if ( type === 'reset' ) {
        nextCounter.countUp.reset();
      } else {
        setTimeout( () => {
          nextCounter.countUp.start( () => {
            slideEl.addClass( 'counted-once' );
          } );
        }, scrollDuration );
      }
    } );
  }
};
const resetCounter = ( e, nextSlide ) => {
  const currSlider = $( e.target ),
    nextSlideEl = currSlider.find( '.slick-slide' ).not( '.slick-cloned' ).eq( nextSlide ),
    isCountedOnce = nextSlideEl.hasClass( 'counted-once' ),
    nextCounters = nextSlideEl.find( '[data-counterup]' );
  if ( nextCounters.length > 0 && !isCountedOnce ) {
    nextCounters.each( ( id, nextCounter ) => {
      nextCounter.countUp.reset();
    } );
  }
};
const reInitCounter = ( e, currentSlide ) => {
  const currSlider = $( e.target ),
    currentSlideEl = currSlider.find( '.slick-slide' ).not( '.slick-cloned' ).eq( currentSlide ),
    isCountedOnce = currentSlideEl.hasClass( 'counted-once' ),
    nextCounters = currentSlideEl.find( '[data-counterup]' );
  if ( nextCounters.length > 0 && !isCountedOnce ) {
    nextCounters.each( ( id, nextCounter ) => {
      nextCounter.countUp.start( () => {
        currentSlideEl.addClass( 'counted-once' );
      } );
    } );

  }
};
export {
  checkActiveSlides,
  videoInitFunc,
  videoAfterChangeFunc,
  slickObserver,
  slideDirection,
  changeActiveSlide,
  autoplayReverseFix,
  cloneTabTitle,
  equalContent,
  addArrowClick,
  verticalSlider,
  startAutoplay,
  pauseAutoplay,
  pauseOnClick,
  removeAsNav,
  cloneMapButton,
  removePauseOption,
  setActiveEventsTab,
  changeActiveEventsTab,
  resetCounter,
  reInitCounter,
  slideCounters
};
