/* eslint-env jquery */
/* global debounce */
require( 'slick-carousel-latest' );
import {
  checkActiveSlides,
  videoInitFunc,
  videoAfterChangeFunc,
  slickObserver,
  slideDirection,
  changeActiveSlide,
  autoplayReverseFix,
  cloneTabTitle,
  equalContent,
  addArrowClick,
  pauseAutoplay,
  startAutoplay,
  pauseOnClick,
  cloneMapButton,
  removePauseOption,
  setActiveEventsTab,
  changeActiveEventsTab,
} from '../custom/slick-custom-functions';
export const getCurrentSlickBreakpoint = () => {
  const viewportWidth = document.body.getBoundingClientRect().width;
  // eslint-disable-next-line no-undef
  const slickBreakPoints = JSON.parse( SLICK_SLIDER_BREAKPOINTS );
  let currentSlickBreakpoint;
  let smallestBreakpoint = Math.max( ...Object.values( slickBreakPoints ) );
  Object.keys( slickBreakPoints ).forEach( breakpoint => {
    if ( viewportWidth < slickBreakPoints[breakpoint] && slickBreakPoints[breakpoint] <= smallestBreakpoint ) {
      smallestBreakpoint = slickBreakPoints[breakpoint];
      currentSlickBreakpoint = breakpoint;
    }
  } );
  return currentSlickBreakpoint;
};

( function ( $, w ) {
  let windowWidth = $( w ).width();

  const initSlick = () => {
    const currentSlickBreakpoint = getCurrentSlickBreakpoint();
    window.currentSlickBreakpoint = currentSlickBreakpoint;

    if ( $( '[data-slick]' ).length ) {
      $( '.bs-section--add-progress-bar' ).each( ( i, slck ) => {
        if( $( slck ).find( '[data-slick]' ).last().find( '> div' ).length > 1 ) {
          $( slck ).find( '[data-slick]' ).last().sliderProgress();
        }
      } );
      $( '.bs-tab-slider--clone-tab-title' ).each( ( i, tabSlider ) => {
        cloneTabTitle( tabSlider );
      } );
      $( '.bs-tab-slider--equal-height' ).each( ( i, tabSlider ) => {
        equalContent( tabSlider );
      } );
      $( '.bs-tab-slider--home-for-commercial' ).each( ( i, tabSlider ) => {
        cloneMapButton( tabSlider );
      } );
      $( '[data-slick]' ).each( ( i, slider ) => {
        removePauseOption( slider );
      } );
      if ( currentSlickBreakpoint ) {
        $( '[data-slick]' )
          .not( `[data-unslick-${currentSlickBreakpoint}]` )
          .not( '.bs-slider-accordion-slick' )
          .not( '.slick-initialized' )
          .slick();
      } else {
        $( '[data-slick]' ).not( '.bs-slider-accordion-slick' ).not( '.slick-initialized' ).slick();
      }
    }
  };

  const autoPlaySpeedFunction = () => {
    $( '.bs-tab-slider--progress-bar :not(.bs-slider-content)[data-slick]' ).each( ( index, ele ) => {
      if ( $( ele ).hasClass( 'slick-initialized' ) ) {
        const slick = $( ele ).slick( 'getSlick' );

        //append wrapper class and animator span to each slick slide element
        $.each( $( ele ).find( '.slick-slide' ), ( i, element ) => {
          const animatorElement = $( element ).find( '.slick-slide-wrapper .slick-slide-animator' );
          animatorElement.unwrap();
          animatorElement.remove();

          $(element).find('.slick-slide-wrapper').children().wrapAll('<div class="slick-slide-title-wrapper"></div>'); // eslint-disable-line
          const $content = $( element ).find( '.slick-slide-title-wrapper' );
          $content.append( '<span class="slick-slide-animator"></span>' );
        } );
        //set animator animation-duration
        if ( slick.options.autoplay === true ) {
          const playSpeed = slick.options.autoplaySpeed;
          $( ele )
            .find( '.slick-slide-animator' )
            .attr( 'data-speed', `${playSpeed}ms` )
            .css( 'animation-duration', `${playSpeed}ms` );
        }
      }
    } );
  };

  // To refresh the initiated slick sliders
  const refreshSlick = () => {
    $( '.slick-initialized' ).each( function ( key ) {
      if ( $( '.slick-initialized' )[key]?.slick ) {
        $( '.slick-initialized' )[key].slick.refresh();
      }
    } );
  };

  // To reset the autoplay enabled sliders to the initial slide when appearing on the viewport
  const resetSlidersWithIntersectionObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const io = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        const sliders = $( entry.target ).find( '.slick-initialized' );
        if ( entry.isIntersecting && !$( entry.target ).hasClass( 'observed-once' ) ) {
          startAutoplay( entry, sliders );
        } else if ( !entry.isIntersecting && !$( entry.target ).hasClass( 'observed-once' ) ) {
          pauseAutoplay( sliders );
        }
      } );
    }, options );

    $.each( $( '.bs-slider' ), ( index, slider ) => {
      io.observe( slider );
    } );
    $.each( $( '.bs-tab-slider' ), ( index, slider ) => {
      io.observe( slider );
    } );
  };

  const debouncedHandlers = () => {
    const dynamicWidth = $( w ).width();
    if ( windowWidth !== dynamicWidth ) {
      initSlick();
      autoPlaySpeedFunction();
      refreshSlick();
      windowWidth = dynamicWidth;
    }
  };

  // To slide between first slide and last slide smoothly when the 'Infinite' mode is enabled
  const slider = $( '[data-slick]' );
  slider
    .on( 'init reinit', ( e, slick ) => {
      // fix autoplay reverse issue on resize
      slick.direction = 1;
      $( e.target ).find( '.slick-slide' ).slice( 0, slick.options.slidesToShow ).addClass( 'bs-slick-inview' );
      if( !slick.options.infinite ) $( e.target ).toggleClass( 'first-slide-active', slick.currentSlide === 0 );
      videoInitFunc( $( e.target ) );
      slickObserver( $( e.target ), slick );
      changeActiveSlide( e );
      addArrowClick( $( e.target ), slick );
      if( $( e.target ).hasClass( 'bs-slider-tabs' ) ) {
        $( e.target ).find( '.slick-slide' ).on( 'click', ( eve ) => {
          const sliders = $( eve.target ).parents( '.bs-tab-slider' ).find( '[data-slick]' );
          pauseOnClick( sliders );
        } );
        $( e.target ).find( '.slick-arrow' ).on( 'click', ( eve ) => {
          const sliders = $( eve.target ).parents( '.bs-tab-slider' ).find( '[data-slick]' );
          pauseOnClick( sliders );
        } );
      }
      if( $( e.target ).hasClass( 'bs-slider-content' ) ) {
        $( e.target ).find( '.slick-slide .media-elements [data-fancybox]' ).on( 'click', ( eve ) => {
          const sliders = $( eve.target ).parents( '.bs-tab-slider' ).find( '[data-slick]' );
          pauseOnClick( sliders );
        } );
      }
    } )
    .on( 'beforeChange', ( e, slick, currentSlide, nextSlide ) => {
      if ( slick.options.autoplay === true ) {
        $( e.target ).find( '.slick-slide' ).removeClass( 'progress-start' );
        $( e.target ).find( `.slick-slide[data-slick-index="${nextSlide}"]` ).addClass( 'progress-start' );
      }
      $( e.target ).addClass( 'bs-slide-moving' );
      $( e.target ).addClass( 'bs-slide-arrows-hide' );
      setTimeout( () => {
        $( e.target ).removeClass( 'bs-slide-arrows-hide' );
      }, 200 );
      if ( slick.options.infinite ) {
        const slidesCount = slick.$slides.length,
          cls = 'slick-current slick-active' + ( slick.options.centerMode ? ' slick-center' : '' );
        let selector = null;
        slideDirection( e, slick, currentSlide, nextSlide );
        if ( nextSlide === 0 ) {
          selector = `.slick-slide[data-slick-index="0"], .slick-slide[data-slick-index="${slidesCount}"]`;
        } else if ( nextSlide === slidesCount - 1 ) {
          selector = `.slick-slide[data-slick-index="-1"], .slick-slide[data-slick-index="${slidesCount - 1}"]`;
        }

        if ( !selector ) return;
        // Adding a timeout since we need to add the active class after the transition has started
        setTimeout( () => {
          $( '.slick-slide', slick.$slider ).removeClass( cls );
          $( selector, slick.$slider ).addClass( cls );
        }, 10 );
      } else {
        $( e.target ).toggleClass( 'last-slide-active', nextSlide === slick.$slides.length - 1 );
        $( e.target ).toggleClass( 'first-slide-active', nextSlide === 0 );
        checkActiveSlides( e, slick, currentSlide, nextSlide );
      }
    } )
    .on( 'afterChange', ( e, slick, currentSlide ) => {
      $( e.target ).removeClass( 'bs-slide-moving bs-slide-move-right bs-slide-move-left' );
      videoAfterChangeFunc( $( e.target ), slick );
      autoplayReverseFix( e, slick, currentSlide );
    } );

  // Calls when the window is fully loaded
  $( w ).on( 'load', () => {
    initSlick();
    autoPlaySpeedFunction();
    resetSlidersWithIntersectionObserver();
    // check events tabs according to upcoming / past events
    if( $( '.bs-section--events-posts .bs-tab-slider' ).length > 0 ) {
      const pathName = window.location.pathname;
      if( pathName.search( '/events/page/' ) > -1 ) {
        localStorage.setItem( 'pastEventTabActive', true );
      }
      changeActiveEventsTab( $( '.bs-section--events-posts .bs-slider-tabs' ) );
      setActiveEventsTab();
    }
  } );

  // Calls on window resize
  $( w ).on( 'resize', debounce( debouncedHandlers, 500 ) );

  // add slider progress bar and counter
  $.fn.sliderProgress = function () {
    if ( $.fn.slick && typeof $.fn.slick !== 'function' ) return;
    const _this = this;
    const _count = _this.find( '> div' ).length;
    _this
      .on( 'beforeChange', ( event, slick, currentSlide, nextSlide ) => {
        const progressWidth = ( ( nextSlide + 1 ) / _count ) * 100;
        _this
          .parent()
          .find( '.active-slide-id' )
          .text( nextSlide + 1 );
        _this
          .parent()
          .find( '.bs-slider-progress__anim' )
          .css( {
            width: progressWidth + '%',
            transitionDuration: slick.options.speed + 'ms',
          } );
      } )
      .on( 'init', function ( event, slick ) {
        const currSlide = slick.currentSlide;
        if ( _this.parent().find( '.bs-slider-progress' ).length === 0 ) {
          _this.parent().append(
            `
								<div class="bs-slider-progress">
									<div class="bs-slider-progress__bar">
										<span class="bs-slider-progress__anim"></span>
									</div>
									<div class="bs-slider-progress__counter-wrapper">
										<span class="active-slide-id">
										${currSlide + 1}</span>/<span class="slide-count">${_count}
										</span>
									</div>
								</div>
							` );
        } else {
          _this
            .parent()
            .find( '.active-slide-id' )
            .text( currSlide + 1 );
        }
        _this
          .parent()
          .find( '.bs-slider-progress__anim' )
          .css( {
            width: ( ( currSlide + 1 ) / _count ) * 100 + '%',
            transitionDuration: slick.options.speed + 'ms',
          } );
      } );
  };
} )( jQuery, window );
